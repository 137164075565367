<template>
  <v-data-table
    v-bind="$attrs"
    v-on="$listeners"
    hide-default-footer
    @click:row="showPaymentDetail"
  >
    <template v-slot:item.fecha_deposito="{ item }">
      <div>
        {{ item.fecha_deposito ? $options.filters.formatDate(item.fecha_deposito): '-' }}
      </div>
    </template>
    <template v-slot:item.nombre="{ item }">
      <div class="d-flex" style="cursor: pointer;">
        <div class="my-auto">
          <h4 class="font-weight-medium text-no-wrap">
            {{ item.usuario.first_name || '-' }}
          </h4>
        </div>
      </div>
    </template>
    <template v-slot:item.apellido="{ item }">
      <div class="d-flex" style="cursor: pointer;">
        <div class="my-auto">
          <h4 class="font-weight-medium text-no-wrap">
            {{ item.usuario.last_name || '-' }}
          </h4>
        </div>
      </div>
    </template>
    <template v-slot:item.email="{ item }">
      <div class="d-flex" style="cursor: pointer;">
        <div class="my-auto">
          <h4 class="font-weight-medium text-no-wrap">
            {{ item.usuario.email || '-' }}
          </h4>
        </div>
      </div>
    </template>
    <template v-slot:item.celular="{ item }">
      <div class="d-flex" style="cursor: pointer;">
        <div class="my-auto">
          <h4 class="font-weight-medium text-no-wrap">
            {{ item.usuario.celular || '-' }}
          </h4>
        </div>
      </div>
    </template>
    <template v-slot:item.documento="{ item }">
      <div class="d-flex" style="cursor: pointer;">
        <div class="my-auto">
          <h4 class="font-weight-medium text-no-wrap">
            {{ item.usuario.documento || '-' }}
          </h4>
        </div>
      </div>
    </template>
    <template v-slot:item.pago_cuotas="{ item }">
      <div class="d-flex" style="cursor: pointer;">
        <div class="my-auto">
          <v-chip dense style="color: white" variant="flat" :color="item.pago_cuotas ? 'primary': 'green'">
            {{ item.pago_cuotas ? 'En cuotas': 'Contado' }}
          </v-chip>
        </div>
      </div>
    </template>
    <template v-slot:footer="slotProps">
      <div class="pagination d-flex justify-space-between ml-auto mr-0 mt-5">
        <p class="ma-auto">{{ slotProps.props.pagination.page }} de {{ slotProps.props.pagination.pageCount }}</p>
        <div>
          <v-btn icon @click="$emit('goBack')" :disabled="slotProps.props.pagination.page === 1">
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn icon @click="$emit('goAhead')" :disabled="next === null">
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    next: {
      type: String,
      default: ''
    }
  },
  methods: {
    showPaymentDetail (item) {
      this.$router.push({ name: 'UserDetail', params: { id: item.usuario.id } })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
