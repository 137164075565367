<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5">
      <PaymentHeader :totalSum="totalSum">
        <template #filters>
          <div>
            <div class="d-flex align-center pa-3">
              <v-autocomplete
                v-model="seccionSeleccionada"
                label="Sección"
                :items="secciones"
                outlined
                dense
                style="max-width: 450px;"
                hide-details
                clearable
                item-text="nombre"
                item-value="id"
                @change="applyFilter"
              />
              <v-select
                label="Tipo de pago de matrícula"
                class="mx-10"
                :items="matriculaPaymentTypes"
                v-model="currentPaymentType"
                item-text="text"
                item-value="value"
                outlined
                dense
                style="max-width: 210px;"
                hide-details
                @change="applyFilter"
              />
              <v-switch
                class="ma-0"
                v-model="pagado" 
                :label="pagado ? 'Solo pagos realizados': 'Solo pagos pendientes'"
                color="secondary"
                hide-details
                inset
                @change="applyFilter(); filters.startDate=''; filters.endDate=''"
              ></v-switch>
            </div>
            <BaseDataRange
              v-if="pagado"
              style="max-width: 474px; margin: 0;"
              @sendStartDate="sendStartDate"
              @sendEndDate="sendEndDate"
              @clearStartDate="filters.startDate=''"
              @clearEndDate="filters.endDate=''"
            />
            
          </div>
        </template>
      </PaymentHeader>
      <PaymentDataTable
        :headers="headerArray"
        :items="paymentsFiltered"
        :options.sync="options"
        :server-items-length="totalCounts"
        :items-per-page="10"
        :loading="loading"
        :next="next"
        @goBack="options.page--"
        @goAhead="options.page++"
      />
      <!-- Table -->
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import BaseDataRange from '@/components/commonComponents/BaseDataRange'
import PaymentHeader from '@/components/dashboardComponents/payments/PaymentHeader'
import PaymentDataTable from '@/components/dashboardComponents/payments/PaymentDataTable'
import { mapState, mapActions } from "vuex"

export default {
  components: {
    BaseDataRange,
    PaymentHeader,
    PaymentDataTable
  },
  data: () => ({
    loading: false,
    pagado: true,
    headerArray: [
      { text: 'Fecha del depósito', value: 'fecha_deposito', sortable: false, align: 'start' },
      { text: 'Nombres', value: 'nombre', sortable: false, align: 'start' },
      { text: 'Apellidos', value: 'apellido', sortable: false, align: 'start' },
      { text: 'Correo', value: 'email', sortable: false, align: 'start' },
      { text: 'Celular', value: 'celular', sortable: false, align: 'start' },
      { text: 'Documento', value: 'documento', sortable: false, align: 'start' },
      { text: 'Sección', value: 'seccion', sortable: false, align: 'start' },
      { text: 'Monto del pago', value: 'monto', sortable: false, align: 'start' },
      { text: 'Tipo de pago de matricula', value: 'pago_cuotas', sortable: false, align: 'start' },
    ],
    paymentsFiltered: [],
    filters: {
      startDate: '',
      endDate: '',
      section: null,
      page: 1,
      search: '',
      state: 'PAGADO'
    },
    options: {},
    previous: null,
    next: null,
    totalCounts: 0,
    startDate: '',
    endDate: '',
    totalSum: '',
    applyingFilter: false,
    seccionSeleccionada: null,
    matriculaPaymentTypes: [
      {text: 'Todos', value: 'Todos'},
      {text: 'En cuotas', value: 'En cuotas'},
      {text: 'Al contado', value: 'Al contado'}
    ],
    currentPaymentType: 'Todos',
  }),
  computed: {
    ...mapState(['searchGlobal', 'secciones',])
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        this.$router.push(`/dashboard/payments/?fecha_inicio=${this.filters.startDate}&fecha_fin=${this.filters.endDate}&seccion=${this.filters.section || ''}&page=${this.filters.page || 1}&search=${this.searchGlobal || ''}&estado=${this.filters.state}`).catch(()=>{})
      }
    },
    /* eslint-disable */
    $route (to, from) {
      this.setQueryParamsLocally()
      this.getDataFromApiFetchData()
    },
    options: {
      handler () {
        if (!this.applyingFilter) {
          this.filters.page = this.options.page
        }
      },
      deep: true,
    },
    searchGlobal () {
      this.$set(this.filters, 'search', this.searchGlobal)
    }
  },
  methods: {
    ...mapActions(['fetchSecciones']),
    // utils ui
    horaDeposito (item) {
      const d = new Date(item)
      return d.toString().split(" ")[4]
    },
    // to call filters watcher
    sendStartDate (item) {
      this.filters.page = 1
      if (this.$route.query.seccion) {
        this.filters.section = this.$route.query.seccion
      }
      this.filters.startDate = item
    },
    sendEndDate (item) {
      this.filters.page = 1
      if (this.$route.query.seccion) {
        this.filters.section = this.$route.query.seccion
      }
      this.filters.endDate = item
    },
    // to call filters watcher
    async applyFilter() {
      this.applyingFilter = true
      this.filters.section = this.seccionSeleccionada || ''
      this.filters.state = this.pagado ? 'PAGADO': 'PENDIENTE'
      this.filters.tipoPago = this.currentPaymentType
      this.options.page = 1
      await this.$router.push(`/dashboard/payments/?fecha_inicio=${this.filters.startDate}&fecha_fin=${this.filters.endDate}&seccion=${this.filters.section || ''}&page=${this.options.page || 1}&search=${this.searchGlobal || ''}&estado=${this.filters.state}&tipo_pago=${this.filters.tipoPago}`).catch(()=>{})
      this.applyingFilter = false
    },
    async fetchPayments (id) {
      const response = await axios.get(`api/admin/matriculas/pagos/?page=${id}&page_size=10`)
      this.totalSum = response.data.total
      return response.data
    },
    
    async getDataFromApiFetchData () {
      this.loading = true
      const response = await this.fetchDataTable()
      this.paymentsFiltered = response.items
      this.loading = false
    },
    async fetchDataTable () {
      const payment = await this.fetchData()
      const items = payment.results
      this.next = payment.next
      this.totalCounts = payment.count
      const total = items.length
      return {
        items,
        total
      }
    },
    async fetchData () {
      try {
        const { data } = await axios.get(`api/admin/matriculas/pagos/?fecha_inicio=${this.$route.query.fecha_inicio || ''}&fecha_fin=${this.$route.query.fecha_fin || ''}&seccion=${this.$route.query.seccion || ''}&page=${this.$route.query.page || 1}&search=${this.searchGlobal || ''}&estado=${this.$route.query.estado || this.filters.state || ''}&page_size=10&tipo_pago=${this.$route.query.tipo_pago || ''}`)
        this.totalCounts = data.count
        this.next = data.next
        this.previous = data.previous
        this.totalSum = data.total
        return data
      } catch (e) {
        this.$root.$confirm({
          title: 'Ups',
          message: e,
          acceptText: 'Ok'
        })
      }
    },
    setQueryParamsLocally () {
      this.startDate = this.$route.query.fecha_inicio
      this.endDate = this.$route.query.fecha_fin
      this.seccionSeleccionada = this.$route.query.seccion
      this.options.page = this.$route.query.page || 1
      this.pagado = this.$route.query.estado !== 'PENDIENTE' ? true: false
      this.currentPaymentType = this.$route.query.tipo_pago
    }
  },
  async created() {
    this.loading = true
    this.setQueryParamsLocally()
    this.fetchSecciones()
    await this.getDataFromApiFetchData()
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.total {
  font-size: 14px;
  line-height: 16px;
  color: #B2BAC4;
}
.sum {
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  color: #2196F3;
  border-bottom: 1px solid #B2BAC4;
}
</style>
