<template>
  <div class="d-sm-flex flex-column align-center justify-space-between">
    <div class="ml-3 d-flex justify-space-between w-100">
      <div>
        <h2 class="font-weight-regular title">Lista de pagos</h2>
        <h6 class="body-2 mb-1 subtitle-2 font-weight-light">
          Datos de registrados
        </h6>
      </div>
      <div class="d-flex justify-center align-center">
        <template v-if="permissions.includes('is_superuser')">
          <span class="total mr-3">
            Total
          </span>
          <span class="sum pr-5">
            {{ totalSum }} soles
          </span>
        </template>
      </div>
    </div>
    <div style="width: 100%" class="">
      <slot name="filters" />
    </div>
    <v-spacer></v-spacer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PaymentHeader',
  props: {
    totalSum: {
      type: [Number, String],
      default: 0
    }
  },
  computed: {
    ...mapGetters(['permissions'])
  }
}
</script>
<style lang="scss" scoped>
.sum {
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  color: #2196F3;
  border-bottom: 1px solid #B2BAC4;
}
</style>
