import { render, staticRenderFns } from "./ThePaymentDataTable.vue?vue&type=template&id=23875d48&scoped=true&"
import script from "./ThePaymentDataTable.vue?vue&type=script&lang=js&"
export * from "./ThePaymentDataTable.vue?vue&type=script&lang=js&"
import style0 from "./ThePaymentDataTable.vue?vue&type=style&index=0&id=23875d48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23875d48",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VAutocomplete,VCard,VCardText,VSelect,VSwitch})
