var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"hide-default-footer":""},on:{"click:row":_vm.showPaymentDetail},scopedSlots:_vm._u([{key:"item.fecha_deposito",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.fecha_deposito ? _vm.$options.filters.formatDate(item.fecha_deposito): '-')+" ")])]}},{key:"item.nombre",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.usuario.first_name || '-')+" ")])])])]}},{key:"item.apellido",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.usuario.last_name || '-')+" ")])])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.usuario.email || '-')+" ")])])])]}},{key:"item.celular",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.usuario.celular || '-')+" ")])])])]}},{key:"item.documento",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('h4',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.usuario.documento || '-')+" ")])])])]}},{key:"item.pago_cuotas",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[_c('div',{staticClass:"my-auto"},[_c('v-chip',{staticStyle:{"color":"white"},attrs:{"dense":"","variant":"flat","color":item.pago_cuotas ? 'primary': 'green'}},[_vm._v(" "+_vm._s(item.pago_cuotas ? 'En cuotas': 'Contado')+" ")])],1)])]}},{key:"footer",fn:function(slotProps){return [_c('div',{staticClass:"pagination d-flex justify-space-between ml-auto mr-0 mt-5"},[_c('p',{staticClass:"ma-auto"},[_vm._v(_vm._s(slotProps.props.pagination.page)+" de "+_vm._s(slotProps.props.pagination.pageCount))]),_c('div',[_c('v-btn',{attrs:{"icon":"","disabled":slotProps.props.pagination.page === 1},on:{"click":function($event){return _vm.$emit('goBack')}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.next === null},on:{"click":function($event){return _vm.$emit('goAhead')}}},[_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1)],1)])]}}])},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }